import React from "react"

export default function MultiLineText({ children, className }) {
  return (
    <div className={className}>
      {children
        ? children.split("\n").map(text => (
            <>
              {text}
              <br />
            </>
          ))
        : ""}
    </div>
  )
}
