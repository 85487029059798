import styled from "styled-components"

const Grid = styled.section`
  display: grid;
  grid-template-columns: ${props => props.columns};
  grid-column-gap: 1rem;
  margin: 0;

  @media screen and (max-width: 700px) {
    display: block;
  }
`

export default Grid
